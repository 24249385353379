import React from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as Facebook } from '../../assets/svgIcons/facebook.svg';
import { ReactComponent as LinbkedIn } from '../../assets/svgIcons/linkedin.svg';
import { ReactComponent as Web } from '../../assets/svgIcons/globe.svg';
import OnlyLogo from '../../assets/images/logo-only.png';
import EuroLogo from '../../assets/images/euro-logo.png';
import { i18n } from '../../i18n';
export const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="footer-wrapper">
      <div className="footer-container">
        <div className="footer-section">
          <span>
            <img src={OnlyLogo} alt="" />
            <img src={EuroLogo} alt="" />
          </span>
          <h3>{i18n(`footer.title`)}</h3>
          <div className="social-media">
            <a
              href="https://www.facebook.com/Shkollaemagjistratures/"
              target="_blank"
              title="Facebook"
            >
              <Facebook fill="white" />
            </a>
            <a
              href="https://www.linkedin.com/company/albanian-school-of-magistrates/"
              target="_blank"
              title="LinkedIn"
            >
              <LinbkedIn fill="white" />
            </a>
            <a
              href="https://www.magjistratura.edu.al/sq/"
              target="_blank"
              title="Web"
            >
              <Web fill="white" />
            </a>
          </div>
          <a
            href="mailto:biblioteka@magjistratura.edu.al"
            className={`footer-link`}
          >
            biblioteka@magjistratura.edu.al
          </a>
        </div>
        <div className="footer-section">
          <h3>{i18n(`footer.quickLinks`)}</h3>
          <NavLink to="/" className={`footer-link`}>
            {i18n(`navbar.main.home`)}
          </NavLink>
          <NavLink to="/books" className={`footer-link`}>
            {i18n(`navbar.main.books`)}
          </NavLink>
          <NavLink to="/magazines" className={`footer-link`}>
            {i18n(`navbar.main.periodic`)}
          </NavLink>
          {localStorage.getItem('idToken') ? (
            <NavLink to="/library" className={`footer-link`}>
              {i18n(`navbar.main.myLibrary`)}
            </NavLink>
          ) : (
            ''
          )}
          <a
            href="https://www.magjistratura.edu.al/sq/"
            target="_blank"
            className={`footer-link`}
          >
            {i18n(`footer.schoolPage`)}
          </a>
        </div>
        <div className="footer-section map">
          <h3>{i18n(`footer.map`)}</h3>
          <iframe
            width="100%"
            height="100%"
            style={{ border: '0', top: '0' }}
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.829883970267!2d19.79278571556021!3d41.33431240724644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135031e0848900d1%3A0x651c5d18764b6924!2sShkolla%20e%20Magjistratur%C3%ABs!5e0!3m2!1sen!2s!4v1629964050225!5m2!1sen!2s`}
          ></iframe>
        </div>
      </div>
      <div className="footer-bottom">
        <div>
          <span>
            Copyright &copy; {year} - {i18n(`footer.copyright`)}
          </span>
          <span>{i18n(`footer.financedBy`)}</span>
        </div>
      </div>
    </div>
  );
};
