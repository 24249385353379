import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';
import { booksApis } from './BooksApi';
import notificationThrower from '../../../helpers/notificationThrower';
import { Book } from '../../../interfaces/books';
import { fetchHomePageData } from './booksSlice';
import { useDispatch } from 'react-redux';
import { i18n } from '../../../i18n';
interface BookState {
  singleBook: Book | null;
  singleBookLoading: boolean;
  error: string | null;
}

const initialState: BookState = {
  singleBook: null,
  singleBookLoading: false,
  error: null,
};

export const saveInLibraria = createAsyncThunk<
  { payload: any; success: boolean },
  any,
  { rejectValue: { payload: any; success: boolean; status: number } }
>('book/saveInLibraria', async (data, { rejectWithValue }) => {
  try {
    const response = await booksApis.saveInLibraria(data);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const removeFromLibraria = createAsyncThunk<
  { payload: any; success: boolean },
  any,
  { rejectValue: { payload: any; success: boolean; status: number } }
>('book/removeFromLibraria', async (id, { rejectWithValue }) => {
  try {
    const response = await booksApis.removeFromLibraria(id);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const fetchSingleBook = createAsyncThunk<
  { payload: any; success: boolean },
  any,
  { rejectValue: { payload: any; success: boolean; status: number } }
>('books/fetchSingleBook', async (id: any, { rejectWithValue }) => {
  try {
    const response = await booksApis.fetchSingleBook(id);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: { resetBookState: () => initialState },
  extraReducers: builder => {
    builder
      .addCase(fetchSingleBook.pending, state => {
        return {
          ...state,
          singleBookLoading: true,
        };
      })
      .addCase(fetchSingleBook.fulfilled, (state, action) => {
        return {
          ...state,
          singleBook: action.payload.payload.data,
          singleBookLoading: false,
        };
      })
      .addCase(fetchSingleBook.rejected, (state, action) => {
        if (action.payload?.status === 404) {
          notificationThrower({
            type: 'error',
            title: i18n(`requestErrors.library.notFound`),
            duration: 6000,
            message: i18n(`requestErrors.library.notFound`),
          });
        } else
          notificationThrower({
            type: 'error',
            title: i18n(`noBackendError`),
            duration: 4000,
            message: i18n(`noBackendError`),
          });
        return {
          ...state,
          error: action.payload as any,
          singleBookLoading: false,
        };
      })
      .addCase(saveInLibraria.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(saveInLibraria.fulfilled, (state, action) => {
        notificationThrower({
          type: 'success',
          title: i18n(`notifications.librarySave`),
          duration: 6000,
          message: '',
        });
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(saveInLibraria.rejected, (state, action) => {
        if (action.payload?.status === 400) {
          notificationThrower({
            type: 'error',
            title: i18n(`requestErrors.library.notRegistered`),
            duration: 6000,
            message: i18n(`requestErrors.library.notRegistered`),
          });
        } else if (action.payload?.status === 404) {
          notificationThrower({
            type: 'error',
            title: i18n(`requestErrors.library.notFound`),
            duration: 6000,
            message: i18n(`requestErrors.library.notFound`),
          });
        } else {
          notificationThrower({
            type: 'error',
            title: i18n(`noBackendError`),
            duration: 6000,
            message: i18n(`noBackendError`),
          });
        }
        return {
          ...state,
          error: action.error.message as any,
          loading: false,
        };
      })
      .addCase(removeFromLibraria.fulfilled, (state, action) => {
        notificationThrower({
          type: 'success',
          title: i18n(`notifications.libraryRemove`),
          duration: 6000,
          message: '',
        });
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(removeFromLibraria.rejected, (state, action) => {
        if (action.payload?.status === 400) {
          notificationThrower({
            type: 'error',
            title: i18n(`requestErrors.library.notRegistered`),
            duration: 6000,
            message: i18n(`requestErrors.library.notRegistered`),
          });
        } else if (action.payload?.status === 404) {
          notificationThrower({
            type: 'error',
            title: i18n(`requestErrors.library.notFound`),
            duration: 6000,
            message: i18n(`requestErrors.library.notFound`),
          });
        } else {
          notificationThrower({
            type: 'error',
            title: i18n(`noBackendError`),
            duration: 6000,
            message: i18n(`noBackendError`),
          });
        }
        return {
          ...state,
          error: action.error.message as any,
          loading: false,
        };
      });
  },
});

export const { resetBookState } = bookSlice.actions;

export const selectSingleBook = (state: RootState) => state.singleBook;
export const selectSingleBookErrorMessage = (state: RootState) =>
  state.singleBook.error;
export const selectSingleBookLoading = (state: RootState) =>
  state.singleBook.singleBookLoading;

export default bookSlice.reducer;
