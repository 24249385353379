import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import notificationThrower from '../../../../helpers/notificationThrower';
import { i18n } from '../../../../i18n';
import { RootState } from '../../../store';
import { registerApi } from './registerApi';

interface RegisterState {
  isLoading: boolean;
  isAuth: boolean;
  error: string | null;
}

const initialState: RegisterState = {
  isLoading: false,
  isAuth: false,
  error: null,
};

export const userRegister = createAsyncThunk<
  { payload: any; success: boolean },
  any,
  { rejectValue: { payload: any; success: boolean } }
>('auth/signup', async (data: any, { rejectWithValue }) => {
  try {
    const response = await registerApi.Register(data);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetRegisterState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(userRegister.pending, state => {
        state.isLoading = true;
      })
      .addCase(userRegister.fulfilled, state => {
        notificationThrower({
          type: 'success',
          title: i18n(`registerPage.success`),
          duration: 15000,
          message: '',
        });
        return {
          ...state,
          isLoading: false,
          isAuth: true,
          error: '',
        };
      })
      .addCase(userRegister.rejected, (state, action) => {
        if (action.payload === undefined) {
          notificationThrower({
            type: 'error',
            title: i18n(`noBackendError`),
            duration: 4000,
            message: i18n(`noBackendError`),
          });
        }
        return {
          ...state,
          isLoading: false,
          isAuth: false,
          error: action.payload as any,
        };
      });
  },
});

export const selectRegister = (state: RootState) => state.register;
export const selectErrorMessage = (state: RootState) => state.register.error;
export const selectLoading = (state: RootState) => state.register.isLoading;
export const selectAuth = (state: RootState) => state.register.isAuth;
export const { resetRegisterState } = registerSlice.actions;
export default registerSlice.reducer;
