import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import FileSaver, { saveAs } from 'file-saver';
import notificationThrower from '../../../helpers/notificationThrower';
import { i18n } from '../../../i18n';
import { User } from '../../../interfaces/users';
import { RootState } from '../../store';
import { usersApis } from './usersApi';

interface UserState {
  users: Array<User>;
  loading: boolean;
  error: string | null;
  singleUser: User | null;
  singleUserLoading: boolean;
  emailSent: boolean;
}

const initialState: UserState = {
  users: [],
  loading: false,
  error: null,
  singleUser: null,
  singleUserLoading: false,
  emailSent: false,
};
export const fetchSingleUser = createAsyncThunk(
  'users/fetchSingleUser',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await usersApis.fetchSingleUser(id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await usersApis.deleteUser(id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const updateUserRole = createAsyncThunk<
  { payload: any; success: boolean; status: number },
  any,
  { rejectValue: { payload: any; success: boolean; status: number } }
>(
  'users/updateUserRole',
  async (args: { data: any; id: number }, { rejectWithValue }) => {
    try {
      const response = await usersApis.updateUserRole(args.data, args.id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const updateSingleUser = createAsyncThunk(
  'users/updateSingleUser',
  async (args: { data: any; id: number }, { rejectWithValue }) => {
    try {
      const response = await usersApis.updateSingleUser(args.data, args.id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const downloadUsers = createAsyncThunk(
  'users/downloadUsers',
  async (args: any, { rejectWithValue }) => {
    try {
      const response = await usersApis.downloadUsers(args);
      var file = new File([response.payload.data], 'Perdoruesit.xlsx');
      saveAs(file);
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const changeUserPassword = createAsyncThunk<
  { payload: any; success: boolean },
  any,
  { rejectValue: { payload: any; success: boolean; status: number } }
>('users/changePassword', async (data: any) => {
  const response = await usersApis.changeUserPassword(data);
  return response;
});
export const sendEmail = createAsyncThunk<
  { payload: any; success: boolean },
  any,
  { rejectValue: { payload: any; success: boolean; status: number } }
>('users/sendEmail', async (data: any) => {
  const response = await usersApis.sendEmail(data);
  return response;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSingleUser.pending, state => {
        return {
          ...state,
          singleUserLoading: true,
        };
      })
      .addCase(fetchSingleUser.fulfilled, (state, action) => {
        return {
          ...state,
          singleUser: action.payload.payload.data,
          singleUserLoading: false,
        };
      })
      .addCase(fetchSingleUser.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 4000,
          message: i18n(`noBackendError`),
        });
        return {
          ...state,
          error: action.payload as any,
          singleUserLoading: false,
        };
      })
      .addCase(deleteUser.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteUser.fulfilled, state => {
        notificationThrower({
          type: 'success',
          title: i18n(`notifications.userDeleted`),
          duration: 6000,
          message: '',
        });
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(deleteUser.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 4000,
          message: i18n(`noBackendError`),
        });
        return {
          ...state,
          error: action.payload as any,
          loading: false,
        };
      })
      .addCase(updateUserRole.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updateUserRole.fulfilled, (state, action) => {
        if (action.payload.payload.status === 204) {
          notificationThrower({
            type: 'success',
            title: i18n(`notifications.noUserEmail`),
            duration: 6000,
            message: '',
          });
        } else {
          notificationThrower({
            type: 'success',
            title: i18n(`notifications.userUpdated`),
            duration: 6000,
            message: '',
          });
        }
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(updateUserRole.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 4000,
          message: i18n(`noBackendError`),
        });
        return {
          ...state,
          error: action.payload as any,
          loading: false,
        };
      })
      .addCase(updateSingleUser.pending, state => {
        return {
          ...state,
          singleUserLoading: true,
        };
      })
      .addCase(updateSingleUser.fulfilled, state => {
        notificationThrower({
          type: 'success',
          title: i18n(`notifications.profileUpdated`),
          duration: 6000,
          message: '',
        });
        return {
          ...state,
          singleUserLoading: false,
        };
      })
      .addCase(updateSingleUser.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 4000,
          message: i18n(`noBackendError`),
        });
        return {
          ...state,
          error: action.payload as any,
          singleUserLoading: false,
        };
      })
      .addCase(downloadUsers.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(downloadUsers.fulfilled, state => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(downloadUsers.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 6000,
          message: '',
        });
        return {
          ...state,
          loading: false,
          error: action.payload as any,
        };
      })
      .addCase(changeUserPassword.pending, state => {
        return {
          ...state,
          singleUserLoading: true,
        };
      })
      .addCase(changeUserPassword.fulfilled, state => {
        notificationThrower({
          type: 'success',
          title: i18n(`notifications.passwordUpdated`),
          duration: 6000,
          message: '',
        });
        return {
          ...state,
          singleUserLoading: false,
        };
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        if (action.error.message === 'Request failed with status code 404') {
          notificationThrower({
            type: 'error',
            title: i18n(`requestErrors.wrongPassword`),
            duration: 6000,
            message: '',
          });
        } else {
          notificationThrower({
            type: 'error',
            title: i18n(`noBackendError`),
            duration: 4000,
            message: i18n(`noBackendError`),
          });
        }
        return {
          ...state,
          loading: false,
          error: action.error.message as any,
          singleUserLoading: false,
        };
      })
      .addCase(sendEmail.pending, state => {
        return {
          ...state,
          loading: true,
          emailSent: false,
        };
      })
      .addCase(sendEmail.fulfilled, state => {
        notificationThrower({
          type: 'success',
          title: i18n(`notifications.emailSend`),
          duration: 6000,
          message: '',
        });
        return {
          ...state,
          loading: false,
          emailSent: true,
        };
      })
      .addCase(sendEmail.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`notifications.noUsersNotified`),
          duration: 4000,
          message: i18n(`notifications.noUsersNotified`),
        });
        return {
          ...state,
          loading: false,
          emailSent: false,
          error: action.error.message as any,
        };
      });
  },
});

export const selectUsers = (state: RootState) => state.users;
export const selectErrorMessage = (state: RootState) => state.users.error;
export const selectLoading = (state: RootState) => state.users.loading;
export const selectSentEmail = (state: RootState) => state.users.emailSent;
export const selectSingleUser = (state: RootState) => state.users.singleUser;
export const selectSingleUserLoading = (state: RootState) =>
  state.users.singleUserLoading;
export const { resetUserState } = usersSlice.actions;
export default usersSlice.reducer;
