import Request from '../../../helpers/request';
import getConfig from '../../../helpers/config';

const config = getConfig();

const { test } = config;

export const usersApis = {
  fetchUsers: (query?: any) =>
    Request.doRequest({
      method: 'get',
      url: `${test.baseUrl}/perdorues`,
      query: query || {},
    }),

  fetchSingleUser: (id: number) =>
    Request.doRequest({
      method: 'get',
      url: `${test.baseUrl}/perdorues/${id}`,
    }),

  deleteUser: (id: number) =>
    Request.doRequest({
      method: 'delete',
      url: `${test.baseUrl}/perdorues/${id}`,
    }),
  updateUserRole: (data: any, id: number) =>
    Request.doRequest({
      method: 'put',
      url: `${test.baseUrl}/perdorues/aktivizo/${id}`,
      data: data,
    }),
  updateSingleUser: (data: any, id: number) =>
    Request.doRequest({
      method: 'put',
      url: `${test.baseUrl}/perdorues/${id}`,
      data: data,
    }),
  downloadUsers: (query?: any) =>
    Request.doRequest({
      method: 'get',
      url: `${test.baseUrl}/perdorues/shkarko-perdoruesit`,
      responseType: 'blob',
      query: query || {},
    }),
  changeUserPassword: (data: any) =>
    Request.doRequest({
      method: 'post',
      url: `${test.baseUrl}/perdorues/ndrysho-password`,
      data: data,
    }),
  sendEmail: (data: any) =>
    Request.doRequest({
      method: 'post',
      url: `${test.baseUrl}/perdorues/dergo_lajmerim`,
      data: data,
    }),
};
