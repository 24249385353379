import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/shm-biblioteka-logo.png';
import avatar from '../../assets/images/avatar.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  getLoggedUser,
  loggedUserId,
  logOut,
} from '../../redux/slices/AUTH/Login/loginSlice';
import { resetBooksState } from '../../redux/slices/Books/booksSlice';
import useOutsideClickChecker from '../../helpers/mouseEvent';
import { getEmail, getUserRole, tokenExpiry } from '../../helpers/getUserRole';
import { ReactComponent as EmailIcon } from '../../assets/svgIcons/email.svg';
import { ReactComponent as Language } from '../../assets/svgIcons/language.svg';
import { ReactComponent as Menu } from '../../assets/svgIcons/menu.svg';
import { ReactComponent as DownArrow } from '../../assets/svgIcons/down-arrow.svg';
import { ReactComponent as UpArrow } from '../../assets/svgIcons/up-arrow.svg';
import { ReactComponent as Close } from '../../assets/svgIcons/close.svg';
import { i18n } from '../../i18n';

export const Navbar: React.FunctionComponent = () => {
  const userName = getEmail();
  const role = getUserRole();
  const [subMenu, setSubMenu] = useState(false);
  const [userDrawer, setUserDrawer] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);
  const [toggleLang, setToggleLang] = useState(false);
  const userId = useSelector(loggedUserId);
  const drawerRef = useRef(null);
  const mobileRef = useRef(null);
  const languageRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const expire = tokenExpiry();
  const handleLogOut = () => {
    localStorage.removeItem('idToken');
    dispatch(logOut(null));
    dispatch(resetBooksState());
    navigate('/login');
    setUserDrawer(false);
    setSubMenu(false);
    setOpenNavbar(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (expire * 1000 < new Date().getTime()) {
      handleLogOut();
    }
    if (
      window.location.pathname.includes('manage') &&
      (role === 'Administrator' || role === 'Menaxher')
    ) {
      setSubMenu(true);
    } else {
      setSubMenu(false);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    if (localStorage.getItem('idToken')) {
      dispatch(getLoggedUser());
    }
  }, [userName]);

  useOutsideClickChecker(drawerRef, setUserDrawer);
  useOutsideClickChecker(mobileRef, setOpenNavbar);
  useOutsideClickChecker(languageRef, setToggleLang);
  const handleLanguage = (lang: string) => {
    localStorage.setItem('language', lang);
    window.location.reload();
    setToggleLang(false);
  };
  return (
    <div className="navbar-wrapper">
      <div className="navbar-header">
        <a href="mailto:biblioteka@magjistratura.edu.al" className="item">
          <EmailIcon className="icon" /> biblioteka@magjistratura.edu.al
        </a>
        <div
          className="item"
          onClick={() => setToggleLang(!toggleLang)}
          ref={languageRef}
        >
          <Language className="icon-lang" />
          {i18n(`navbar.language`)}
          {toggleLang ? (
            <div className="language-dropdown">
              <span
                className="option"
                onClick={() => {
                  handleLanguage('al');
                }}
              >
                Shqip
              </span>
              <span
                className="option"
                onClick={() => {
                  handleLanguage('en');
                }}
              >
                English
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="navbar-container">
        <div className="logo-container">
          <Link to="/">
            <img src={logo} alt="logo" className="logo" />
          </Link>
        </div>
        <div
          className="mobile-navbar-menu"
          onClick={() => {
            setOpenNavbar(!openNavbar);
          }}
        >
          <Menu className="menu-svg" />
        </div>
        <div
          className={`route-container ${
            role === 'Administrator' || role === 'Menaxher'
              ? 'admin-navbar'
              : 'user-navbar'
          }`}
        >
          <NavLink to="/" className={`link`}>
            {i18n(`navbar.main.home`)}
          </NavLink>
          <NavLink to="/books" className={`link`}>
            {i18n(`navbar.main.books`)}
          </NavLink>
          <NavLink to="/magazines" className={`link`}>
            {i18n(`navbar.main.periodic`)}
          </NavLink>
          {localStorage.getItem('idToken') ? (
            <>
              <NavLink to="/library" className="link">
                {i18n(`navbar.main.myLibrary`)}
              </NavLink>
              {role === 'Administrator' || role === 'Menaxher' ? (
                <NavLink
                  to="/manage/books"
                  className={`link ${
                    window.location.pathname.includes('manage') && 'active'
                  }`}
                >
                  {i18n(`navbar.main.management`)}
                </NavLink>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div
          className="avatar-container"
          ref={drawerRef}
          onClick={() => {
            if (localStorage.getItem('idToken')) {
              setUserDrawer(!userDrawer);
            } else {
              navigate('/login');
            }
          }}
        >
          <span className="avatar-username">
            {userName ? userId?.username : 'Log In'}
          </span>
          <img src={avatar} alt="avatar" className="avatar" />
          {userDrawer === true ? (
            <div className="user-options">
              <span
                className="option"
                onClick={() => {
                  navigate('/account');
                  setUserDrawer(!userDrawer);
                }}
              >
                {i18n(`navbar.userOptions.myProfile`)}
              </span>
              <span
                className="option"
                style={{ color: 'red' }}
                onClick={handleLogOut}
              >
                {i18n(`navbar.userOptions.logout`)}
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <div
        className="management-navbar"
        style={subMenu === false ? { display: 'none' } : {}}
      >
        <NavLink
          to="/manage/books"
          className={`link ${
            window.location.pathname === '/manage/books' && 'manage-active'
          }`}
          title={i18n(`navbar.main.books`)}
        >
          {i18n(`navbar.main.books`)}
        </NavLink>
        <NavLink
          to="/manage/magazines"
          className={`link ${
            window.location.pathname === '/manage/magazines' && 'manage-active'
          }`}
          title={i18n(`navbar.main.periodic`)}
        >
          {i18n(`navbar.main.periodic`)}
        </NavLink>
        <NavLink
          to="/manage/create-book"
          className={`link ${
            window.location.pathname === '/manage/create-book' &&
            'manage-active'
          }`}
          title={i18n(`navbar.manage.createBook`)}
        >
          {i18n(`navbar.manage.createBook`)}
        </NavLink>
        <NavLink
          to="/manage/authors"
          className={`link ${
            window.location.pathname === '/manage/authors' && 'manage-active'
          }`}
          title={i18n(`navbar.manage.author`)}
        >
          {i18n(`navbar.manage.author`)}
        </NavLink>
        <NavLink
          to="/manage/send-email"
          className={`link ${
            window.location.pathname === '/manage/send-email' && 'manage-active'
          }`}
          title={i18n(`navbar.manage.notif`)}
        >
          {i18n(`navbar.manage.notif`)}
        </NavLink>
        {role === 'Administrator' ? (
          <NavLink
            to="/manage"
            className={`link ${
              window.location.pathname === '/manage' && 'manage-active'
            }`}
            title={i18n(`navbar.manage.users`)}
          >
            {i18n(`navbar.manage.users`)}
          </NavLink>
        ) : (
          <></>
        )}
      </div>
      <div
        className={`mobile-navbar-container ${
          openNavbar ? 'nav-slide-in' : 'nav-slide-out'
        }`}
        ref={mobileRef}
      >
        <div className="mobile-navbar-wrapper">
          <div className="mobile-nav-header">
            <div
              className="mobile-avatar"
              onClick={() => {
                if (!localStorage.getItem('idToken')) {
                  navigate('/login');
                  setOpenNavbar(false);
                }
              }}
            >
              <span className="avatar-username">
                {userName ? userId?.username : 'Log In'}
              </span>
              <img src={avatar} alt="avatar" className="avatar" />
            </div>

            <div
              onClick={() => {
                setOpenNavbar(!openNavbar);
              }}
              className="close-arrow"
            >
              <Close width="20px" height="20px" />
            </div>
          </div>
          <div className="mobile-route-container">
            <NavLink
              to="/"
              className={`link`}
              onClick={() => setOpenNavbar(false)}
            >
              {i18n(`navbar.main.home`)}
            </NavLink>
            <NavLink
              to="/books"
              className={`link`}
              onClick={() => setOpenNavbar(false)}
            >
              {i18n(`navbar.main.books`)}
            </NavLink>
            <NavLink
              to="/magazines"
              className={`link`}
              onClick={() => setOpenNavbar(false)}
            >
              {i18n(`navbar.main.periodic`)}
            </NavLink>
            {localStorage.getItem('idToken') ? (
              <>
                <NavLink
                  to="/library"
                  className="link"
                  onClick={() => setOpenNavbar(false)}
                >
                  {i18n(`navbar.main.myLibrary`)}
                </NavLink>
                {role === 'Administrator' || role === 'Menaxher' ? (
                  <span
                    className={`link ${
                      window.location.pathname.includes('manage') && 'active'
                    }`}
                    onClick={() => setSubMenu(!subMenu)}
                  >
                    {i18n(`navbar.main.management`)}
                    {subMenu ? (
                      <UpArrow
                        className="down-arrow"
                        style={subMenu ? { fill: '#3f78e0' } : {}}
                      />
                    ) : (
                      <DownArrow
                        className="down-arrow"
                        style={subMenu ? { fill: '#3f78e0' } : {}}
                      />
                    )}
                  </span>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
          <div
            className="management-mobile-navbar"
            style={subMenu === false ? { display: 'none' } : {}}
          >
            <NavLink
              to="/manage/books"
              className={`link ${
                window.location.pathname === '/manage/books' && 'manage-active'
              }`}
              title={i18n(`navbar.main.books`)}
              onClick={() => setOpenNavbar(false)}
            >
              {i18n(`navbar.main.books`)}
            </NavLink>
            <NavLink
              to="/manage/magazines"
              className={`link ${
                window.location.pathname === '/manage/magazines' &&
                'manage-active'
              }`}
              title={i18n(`navbar.main.periodic`)}
              onClick={() => setOpenNavbar(false)}
            >
              {i18n(`navbar.main.periodic`)}
            </NavLink>
            <NavLink
              to="/manage/create-book"
              className={`link ${
                window.location.pathname === '/manage/create-book' &&
                'manage-active'
              }`}
              title={i18n(`navbar.manage.createBook`)}
              onClick={() => setOpenNavbar(false)}
            >
              {i18n(`navbar.manage.createBook`)}
            </NavLink>
            <NavLink
              to="/manage/authors"
              className={`link ${
                window.location.pathname === '/manage/authors' &&
                'manage-active'
              }`}
              title={i18n(`navbar.manage.author`)}
              onClick={() => setOpenNavbar(false)}
            >
              {i18n(`navbar.manage.author`)}
            </NavLink>
            <NavLink
              to="/manage/send-email"
              className={`link ${
                window.location.pathname === '/manage/send-email' &&
                'manage-active'
              }`}
              title={i18n(`navbar.manage.notif`)}
              onClick={() => setOpenNavbar(false)}
            >
              {i18n(`navbar.manage.notif`)}
            </NavLink>
            {role === 'Administrator' ? (
              <NavLink
                to="/manage"
                className={`link ${
                  window.location.pathname === '/manage' && 'manage-active'
                }`}
                title={i18n(`navbar.manage.users`)}
                onClick={() => setOpenNavbar(false)}
              >
                {i18n(`navbar.manage.users`)}
              </NavLink>
            ) : (
              <></>
            )}
          </div>
          {localStorage.getItem('idToken') ? (
            <div className="other-options">
              <span
                className={`option ${
                  window.location.pathname.includes('account') && 'active'
                }`}
                onClick={() => {
                  navigate('/account');
                  setOpenNavbar(false);
                }}
              >
                {i18n(`navbar.userOptions.myProfile`)}
              </span>
              <span
                className="option"
                style={{ color: 'red' }}
                onClick={handleLogOut}
              >
                {i18n(`navbar.userOptions.logout`)}
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
