import React, { useState } from 'react';
import { ReactComponent as UpArrow } from '../../assets/svgIcons/up-arrow-circle.svg';

export const BackToTop: React.FunctionComponent = () => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1000) {
      setVisible(true);
    } else if (scrolled <= 1000) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  window.addEventListener('scroll', toggleVisible);
  return (
    <button
      style={{ display: visible ? 'inline' : 'none' }}
      className="btn-top"
    >
      <UpArrow className="back-top-svg" onClick={scrollToTop} />
    </button>
  );
};
