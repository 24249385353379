import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { loginApi } from './loginApi';
import jwt_decode from 'jwt-decode';
import { createAction } from '@reduxjs/toolkit';
import notificationThrower from '../../../../helpers/notificationThrower';
import { i18n } from '../../../../i18n';
import { LoggedUser } from '../../../../interfaces/users';

interface LoginState {
  isLoading: boolean;
  isAuth: boolean;
  userRole: string | null;
  error: string | null;
  loggedUserId: LoggedUser | null;
}

const initialState: LoginState = {
  isLoading: false,
  isAuth: false,
  userRole: null,
  error: null,
  loggedUserId: null,
};

export const userLogin = createAsyncThunk<
  { payload: any; success: boolean },
  any,
  { rejectValue: { payload: any; success: boolean; status: number } }
>('auth/login', async (data: any, { rejectWithValue }) => {
  try {
    const response = await loginApi.Login(data);
    return response;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const logOut = createAction<null>('auth/logOut');

export const getLoggedUser = createAsyncThunk('auth/loggedUser', async () => {
  const response = await loginApi.LoggedUser();
  return response;
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoggedState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, state => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        localStorage.setItem('idToken', action.payload.payload.data.jwt);
        const decoded: any = jwt_decode(action.payload.payload.data.jwt);
        return {
          ...state,
          userRole: decoded.role[0],
          isLoading: false,
          isAuth: true,
          error: '',
        };
      })
      .addCase(userLogin.rejected, (state, action) => {
        if (action.payload === undefined) {
          notificationThrower({
            type: 'error',
            title: i18n(`noBackendError`),
            duration: 4000,
            message: i18n(`noBackendError`),
          });
        }
        return {
          ...state,
          isLoading: false,
          isAuth: false,
          error: action.payload as any,
        };
      })
      .addCase(logOut, (state, action) => {
        return {
          ...state,
          isLoading: false,
          isAuth: false,
          userRole: null,
          error: null,
        };
      })
      .addCase(getLoggedUser.pending, state => {
        return {
          ...state,
          isLoading: true,
        };
      })
      .addCase(getLoggedUser.fulfilled, (state, action) => {
        return {
          ...state,
          loggedUserId: action.payload.payload.data,
          isLoading: false,
        };
      })
      .addCase(getLoggedUser.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 4000,
          message: i18n(`noBackendError`),
        });
        return {
          ...state,
          error: action.error.message as any,
          isLoading: false,
        };
      });
  },
});

export const selectLogin = (state: RootState) => state.login;
export const selectUserRole = (state: RootState) => state.login.userRole;
export const selectErrorMessage = (state: RootState) => state.login.error;
export const selectLoading = (state: RootState) => state.login.isLoading;
export const selectAuth = (state: RootState) => state.login.isAuth;
export const loggedUserId = (state: RootState) => state.login.loggedUserId;
export const { resetLoggedState } = loginSlice.actions;

export default loginSlice.reducer;
