import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { authorsApis } from './AuthorsApi';
import notificationThrower from '../../../helpers/notificationThrower';
import { Author } from '../../../interfaces/books';
import { i18n } from '../../../i18n';

interface AuthorsState {
  authors: Array<Author>;
  loading: boolean;
  totalPages: number;
  currentPage: number;
  error: string | null;
  singleAuthor: Author | null;
  singleAuthorLoading: boolean;
}

const initialState: AuthorsState = {
  authors: [],
  totalPages: 0,
  currentPage: 0,
  loading: false,
  error: null,
  singleAuthor: null,
  singleAuthorLoading: false,
};

export const fetchAuthors = createAsyncThunk(
  'authors/fetchAuthors',
  async (args: any, { rejectWithValue }) => {
    try {
      const response = await authorsApis.fetchAuthors();
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const createAuthor = createAsyncThunk(
  'authors/createAuthors',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await authorsApis.createAuthor(data);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const deleteAuthor = createAsyncThunk<
  { payload: any; success: boolean },
  any,
  { rejectValue: { payload: any; success: boolean; status: number } }
>('authors/deleteAuthors', async (id: number) => {
  const response = await authorsApis.deleteAuthor(id);
  return response;
});
export const updateAuthor = createAsyncThunk(
  'authors/updateAuthors',
  async (args: { data: any; id: number }, { rejectWithValue }) => {
    try {
      const response = await authorsApis.updateAuthor(args.data, args.id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);
export const fetchSingleAuthor = createAsyncThunk(
  'authors/fetchSingleAuthor',
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await authorsApis.fetchSingleAuthor(id);
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const authorsSlice = createSlice({
  name: 'author',
  initialState,
  reducers: {
    resetAuthorState: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAuthors.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(fetchAuthors.fulfilled, (state, action) => {
        return {
          ...state,
          authors: action.payload?.payload?.data?.autoret ?? [],
          loading: false,
        };
      })
      .addCase(fetchAuthors.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 3000,
          message: i18n(`noBackendError`),
        });
        return {
          ...state,
          error: action.payload as any,
          loading: false,
        };
      })
      .addCase(fetchSingleAuthor.pending, state => {
        return {
          ...state,
          singleAuthorLoading: true,
        };
      })
      .addCase(fetchSingleAuthor.fulfilled, (state, action) => {
        return {
          ...state,
          singleAuthor: action.payload.payload.data,
          singleAuthorLoading: false,
        };
      })
      .addCase(fetchSingleAuthor.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 3000,
          message: i18n(`noBackendError`),
        });
        return {
          ...state,
          error: action.payload as any,
          loading: false,
        };
      })
      .addCase(createAuthor.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(createAuthor.fulfilled, (state, action) => {
        notificationThrower({
          type: 'success',
          title: i18n(`notifications.authorCreated`),
          duration: 7000,
        });
        return {
          ...state,
          authors: [],
          loading: false,
        };
      })
      .addCase(createAuthor.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 6000,
          message: i18n(`noBackendError`),
        });
        return {
          ...state,
          error: action.payload as any,
          loading: false,
        };
      })
      .addCase(deleteAuthor.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(deleteAuthor.fulfilled, state => {
        notificationThrower({
          type: 'success',
          title: i18n(`notifications.authorDeleted`),
          duration: 7000,
        });
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(deleteAuthor.rejected, (state, action) => {
        if (action.error.message === 'Request failed with status code 409') {
          notificationThrower({
            type: 'error',
            title: i18n(`requestErrors.authors.AuthorInUse`),
            duration: 6000,
            message: i18n(`requestErrors.authors.AuthorInUse`),
          });
        } else {
          notificationThrower({
            type: 'error',
            title: i18n(`noBackendError`),
            duration: 6000,
            message: i18n(`noBackendError`),
          });
        }
        return {
          ...state,
          error: action.payload as any,
          loading: false,
        };
      })
      .addCase(updateAuthor.pending, state => {
        return {
          ...state,
          loading: true,
        };
      })
      .addCase(updateAuthor.fulfilled, state => {
        notificationThrower({
          type: 'success',
          title: i18n(`notifications.authorChanged`),
          duration: 7000,
        });
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(updateAuthor.rejected, (state, action) => {
        notificationThrower({
          type: 'error',
          title: i18n(`noBackendError`),
          duration: 6000,
          message: i18n(`noBackendError`),
        });
        return {
          ...state,
          error: action.payload as any,
          loading: false,
        };
      });
  },
});

export const selectAuthors = (state: RootState) => state.authors;
export const selectErrorMessage = (state: RootState) => state.authors.error;
export const selectLoading = (state: RootState) => state.authors.loading;
export const selectSingleAuthor = (state: RootState) =>
  state.authors.singleAuthor;
export const singleAuthorLoading = (state: RootState) =>
  state.authors.singleAuthorLoading;
export const { resetAuthorState } = authorsSlice.actions;

export default authorsSlice.reducer;
